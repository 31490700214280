import React, { useState } from "react"
import Layout from "../components/layout"
import { Grid, Box, Embed, Heading } from "theme-ui"

const style = {
  heading: {
    textAlign: "center",
    fontSize: "25px",
    fontFamily: "Josefin Sans",
    fontWeight: "100",
    marginBottom: "20px",
    maxWidth: "60%",
    margin: "auto",
  },
  title: { textDecoration: "underline" },
}

function KaoticaPage() {
  const [burgerIsOpen, setBurgerIsOpen] = useState(false)
  const videos = [
    {
      url: "https://player.vimeo.com/video/732236241?h=109c0be4b7",
      alt: "Oceandrum",
    },
    {
      url: "https://player.vimeo.com/video/802152058?h=38fa85286d",
      alt: "Richmond Park",
    },
    {
      url: "https://player.vimeo.com/video/733503137?h=2c36879764",
      alt: "Mother Mary",
    },
    {
      url: "https://player.vimeo.com/video/732231921?h=0b41a30d6a",
      alt: "Questions",
    },
    {
      url: "https://player.vimeo.com/video/735664524?h=98c047129b",
      alt: "Dreams They Are (Sunny)",
    },
  ]

  return (
    <Layout burgerIsOpen={burgerIsOpen} setBurgerIsOpen={setBurgerIsOpen}>
      <Heading style={style.heading}>
        <br />
        <br />

        <h1>
          <span style={{ color: "red" }}>Kaotica Live </span>
          is a branded campaign featuring the Kaotica Eyeball, a patented mobile
          technology that can transform any space into a professional vocal
          recording booth. The mini concert series features up and coming
          vocalists recording in unique destinations around the world.
          {/* is an ongoing
          branded content series, featuring up and coming vocal artists doing
          remote recording sessions in unique settings around the world.  */}
        </h1>
        <br />
      </Heading>

      <Grid gap={5} sx={{ width: "85%", mx: "auto" }} columns={[1]}>
        {videos.map(video => {
          return (
            <Box key={video.alt} bg="primary">
              <Embed
                src={video.url}
                alt={video.alt}
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              />
            </Box>
          )
        })}
      </Grid>
    </Layout>
  )
}

export default KaoticaPage
